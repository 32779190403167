import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
import mng from '../images/ob/interview_iwase.jpg';
import tobori from '../images/ob/ob_tobori_top.jpg';
import toboriImg1 from '../images/ob/toboriImg1.jpg';
import toboriImg2 from '../images/ob/toboriImg2.jpg';
// import * as ROUTES from '../src/constants/routes';

// <div className="ob-top-align">
// <img className="ob-top-info" alt="sakai" src={toboriTop} />
// </div>
class ToboriPage extends React.Component {

  render() {
    return (
      <Fragment>
      <title>地方部員インタビュー　戸堀春輝|國學院大學久我山高等学校　バスケットボール部</title>
        <h2 className="content-title-black-ob">OBインタビュー</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'rgb(24, 23, 51)', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
        <h4 className="vertical-timeline-element-subtitle">2年 岩瀬宥太</h4>
          <div className="mng-text">
          本日は、お忙しい中、インタビューに応じて下さり、ありがとうございます。
國學院久我山高校バスケ部２年デジタル運営チームの岩瀬と申します。本日は、どうぞよろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <h4 className="vertical-timeline-element-subtitle">OB 戸堀春輝</h4>
          <div className="ob-text">
            よろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'rgb(24, 23, 51)', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
            ではまず戸堀さんの自己紹介からよろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
            現在は、明治大学経営学部公共経営学科に所属しています。部活動は、体育会男子バスケットボール部に所属しています。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
ありがとうございます。では、早速質問をさせていただきます。まず、國學院久我山高校に入学した理由について教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          わかりました。國學院久我山高校には、もともと兄が所属していて、兄から学校の様子や部活動の雰囲気、バスケ部の実績等を聞き、魅力的に感じたのが一番の理由です。もちろん、自分の意思もあり、なるべく東京都でバスケットボールレベルが上位、且つ勉強も重視している学校に行きたかったので、國學院久我山高校を選びました。
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          そうなんですね！ちなみに、お兄さんが國學院久我山高校出身ということで、お兄さんの姿を見て、久我山高校バスケ部の具体的にどのような所が魅力的に感じたのでしょうか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          兄だけではないですが、周りのメンバーも一生懸命練習に取り組んでいる姿に魅力を感じ、尊敬できる人達と同じ舞台で結果を出したいと思いました。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます！戸堀さんの言う通り、久我山高校バスケ部のメンバーは、練習に一生懸命且つ、楽しく取り組んでいますよね！凄く雰囲気が良い中で練習ができていると思います。では、次に実際に國學院久我山高校に入学されて、良かったことを勉強面、部活面で教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          勉強面では毎週、小テストがあり、良い意味で勉強に取り組む環境があるので、周りについていこうと思い勉強する習慣が身につきました。それは、今でも大学で勉強をしっかりと続けられる一つの要因になっています。部活面では、結果を残せることが重要だと思っていて、自分の時は、３年連続でインターハイに出場する事が出来たので良かったと思います。
        </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        >
          <div className="ob-text">
          <img className="obImg" alt="sakai" src={toboriImg1} />
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございました。自分も國學院久我山高校で学校生活を送る中で、勉強面では、小テストなど周りと切磋琢磨できる所が國學院久我山高校の良さだと思います。では、次の質問に移らせて頂きます。國學院久我山高校の文武両道の印象をについて教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          そうですね。國學院久我山高校は、バスケ部だけでなくサッカー部やラグビー部なども全国大会に出場していて、他の部も結果を残しています。一方で、トップレベルの部活でも、試験前には監督やコーチから、試験でしっかりと良い点数を取るように言われます。監督やコーチからも勉強面については言及されるので、勉強への意識も高まるような体制になっていますね。その上で、みんな妥協せずに部活動も頑張るので、國學院久我山高校の文武両道は高いレベルで保たれていると思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          バスケ部だけでなく、他の部活も東京都トップレベルなのは、すごい事ですよね。且つ、みんな文武両道をしに國學院久我山高校に入学して来ているので、文武両道が保たれる訳ですね！ちなみに、戸堀さんが高校生だった時の國學院久我山高校の雰囲気はどのような感じだったのでしょうか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          勉強面ですと、先ほど言った通り一生懸命取り組む環境ができていて、先生方の教え方も丁寧で授業内容も面白いことが多いですね。クラスでも楽しく勉強できていました。部活面では、下校時刻が早いので練習時間が長く取れないのですが、その中でも自分達が成長できるように、質の高い練習を集中して行う、という雰囲気がありましたね！
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          そうなんですね！短い時間の中で、質の高い練習を集中して行う、という雰囲気は今も部内にありますね。やはり、そこは変わらずなんですね！では次の質問に行きます。戸堀さんが思う久我山高校バスケ部に所属していたからこそ、成長出来たことについて教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          先ほども言ったように文武共にレベルが高い学校ということを理解して入ったので、周りのレベルが高いと成長できる幅も大きいと思います。久我山高校バスケ部自体は、身長の大きい選手が多いです。酒井監督は大きい選手を育てるのが上手で、自分も身長が大きくて、大きさを活かしたプレーを学べたと思います。また、インサイドプレーだけでなく、アウトサイドのプレーも学ぶことができました。おかげで、大学では幅の広いプレーを武器に頑張っています。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        >
          <div className="ob-text">
          <img className="obImg" alt="sakai" src={toboriImg2} />
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          確かに久我山高校バスケ部の選手は、身長の大きな選手が多いですよね。背の高い選手は戸堀さんの仰る通り、幅広いプレーができるように指導されていると思います。一方で、ガード・フォワード陣も、如何に背の高い選手を上手く使うかや、ゲームプランを考える力など物凄く身につくと思っています。Bリーグもでき、身長の高い選手と一緒にプレイするスキルは今後求められるスキルの１つですよね！すみません。つい語ってしまいました（笑）ちなみに大学生活面で何か活かされている事はありますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          そうですね。やはり小テストが毎週あったので、勉強の習慣が身についた事はありますね。おかげで明治大学のバスケ部内では常に上位の成績が保てています。それによって部活も気持ちよく取り組めるのは、國學院久我山高校での経験が活きているからだと思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございました。それでは、最後に國學院久我山高校、そしてバスケ部に入学希望がある、中学生又は保護者にメッセージをお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          勉強面でも部活面でもレベルの高い学校なので、成長できる度合いが他の学校とは確実に違うと思います。頑張れば、頑張るほど成長できる環境がありますので、ぜひ入学してもらい、久我山高校バスケ部を強くしてもらいたいと思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます！これからも久我山高校バスケ部を、より良くしていくために頑張りますので、応援よろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          こちらこそありがとうございました。またお邪魔する機会があると思うのでよろしくお願いします。
        </div>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <ToboriPage />
  </Layout>
);
